import { FC } from 'react';
import PhoneInput, { MuiPhoneNumberProps } from 'material-ui-phone-number';
import { Controller, FieldValues } from 'react-hook-form';
import { Phone } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { BaseControlledField } from '../interfaces';
import { validatePhoneNumber } from '../utils';

export type ControlledPhoneInputProps<F extends FieldValues = any> = BaseControlledField<F> & MuiPhoneNumberProps & {
  validatePhone?: boolean;
  transformValue?: (value: string) => string;
};

const normalizeValue = (v = '') => {
  let value = v.trim();
  if (!value) {
    value = '+39 ';
  } else if (!value.startsWith('+39')) {
    value = `+39 ${value}`;
  }
  return value;
};

export const ControlledPhoneInput: FC<
  ControlledPhoneInputProps
> = ({ control, name, rules = {}, transformValue = (v: any) => v, required, validatePhone, defaultValue, ...props }) => {
  if (!!required) {
    rules.required = required;
  }
  if (validatePhone) {
    rules.validate = validatePhoneNumber;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => {
        const { error } = fieldState;

        return (
          <PhoneInput
            defaultCountry="it"
            preferredCountries={['it']}
            variant="outlined"
            disableDropdown
            // disableCountryCode
            disableAreaCodes
            type="tel"
            fullWidth
            {...props}
            {...field}
            onBlur={e => {
              field.onBlur();
              if (props.onBlur) {
                props.onBlur(e);
              }
            }}
            onChange={v => {
              field.onChange(transformValue(normalizeValue(v as string)));
            }}
            value={normalizeValue(field.value)}
            required={!!required}
            error={!!error}
            helperText={error?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone color="secondary"/>
                </InputAdornment>
              ),
              ...props.InputProps,
            }}
          />
        );
      }}
    />
  );
};
