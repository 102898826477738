import { ReactNode, useCallback, useMemo } from 'react';
import { Actions, useStore } from '../context';
import { DialogAction } from '../actions';

export interface UseDialogProps extends Omit<DialogAction, 'type' | 'title' | 'body'> {
  onClose?: () => void;
  title: ReactNode;
  body: ReactNode;
}

export const useDialog = (rootOptions?: Pick<UseDialogProps, 'onClose'>) => {
  const { dispatch } = useStore();

  const close = useCallback((onClose?: () => void) => {
    dispatch({
      type: Actions.DIALOG_CLOSE,
    });
    const onCloseFn = onClose || rootOptions?.onClose;
    if (onCloseFn && typeof onCloseFn === 'function') {
      onCloseFn();
    }
  }, [dispatch, rootOptions]);

  const open = useCallback((options: UseDialogProps) => {
    dispatch({
      type: Actions.DIALOG_OPEN,
      ...options,
      onClose: () => close(options.onClose),
    });
  }, [dispatch]);

  return useMemo(() => ({
    open,
    close,
  }), [open, close]);
};
