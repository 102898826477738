import { FC, forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { BaseControlledField } from '../interfaces';

export type ControlledTextFieldProps<F = any> = BaseControlledField<F> & TextFieldProps & {
  transformValue?: (value: string) => string;
};

export const ControlledTextField = forwardRef<HTMLDivElement, ControlledTextFieldProps>((
  { name, required, rules = {}, control, transformValue = (v) => v, defaultValue, ...props },
  ref,
) => {
  if (!!required) {
    rules.required = required;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { ref: cRef, ...field }, fieldState }) => {
        const { error } = fieldState;
        return (
          <TextField
            fullWidth
            variant="outlined"
            error={!!error}
            helperText={error?.message}
            {...props}
            {...field}
            inputRef={cRef}
            ref={ref}
            onBlur={e => {
              field.onBlur();
              if (props.onBlur) {
                props.onBlur(e);
              }
            }}
            onChange={e => {
              field.onChange(transformValue(e.target.value));
            }}
            required={!!required}
          />
        );
      }}
    />
  );
});
