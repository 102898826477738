import { FC } from 'react';
import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { BaseControlledField } from '../interfaces';

export type ControlledFormControlLabelProps<F extends FieldValues = any> = BaseControlledField<F>
  & Omit<FormControlLabelProps, 'onChange' | 'name' | 'control' | 'checked'>
  & { formControl: FormControlLabelProps['control'] };

export const ControlledFormControlLabel: FC<ControlledFormControlLabelProps> = ({ control, name, rules = {}, formControl, required, ...props }) => {
  if (!!required) {
    rules.required = required;
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, ...fieldProps } }) => (
        <FormControlLabel
          onChange={(e, checked) => {
            onChange(checked);
          }}
          checked={value}
          control={formControl}
          {...props}
          {...fieldProps}
        />
      )}
    />
  );
};
