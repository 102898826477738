import { FC } from 'react';
import { Checkbox, CheckboxProps as MuiCheckboxProps } from '@mui/material';
import { ControlledFormControlLabel, ControlledFormControlLabelProps } from './controlled-form-control-label';

export type ControlledCheckboxProps = Omit<ControlledFormControlLabelProps, 'formControl'> & {
  CheckboxProps?: MuiCheckboxProps;
  color?: MuiCheckboxProps['color'];
};

export const ControlledCheckbox: FC<ControlledCheckboxProps> = ({ CheckboxProps, color, ...props }) => (
  <ControlledFormControlLabel
    {...props}
    formControl={<Checkbox color={color} {...CheckboxProps}/>}
  />
);
