
function invariant(cond: any, message: string): asserts cond {
  if (!cond) throw new Error(message);
}

function generatePath(path: string, params: Record<string, any> = {}): string {
  return path
    .replace(/:(\w+)/g, (_, key) => {
      invariant(params[key] != null, `Missing ":${key}" param`);
      return params[key]!;
    })
    .replace(/\/*\*$/, _ => params['*'] == null ? '' : params['*'].replace(/^\/*/, '/'));
}

export const getRouteFactory = <RouteKeys = any>(routes: Map<RouteKeys, string> | Record<string, string>, fallbackRoute = '/') => {
  const routeMap = !(routes instanceof Map) ? new Map<RouteKeys, string>(Object.entries(routes) as any) : routes;

  return function getRoute(key: RouteKeys, replaces?: Record<string, any>) {
    if (!routeMap.has(key)) {
      return fallbackRoute; // Fallback to index
    }
    const path = routeMap.get(key)!;
    try {
      return generatePath(path, replaces);
    } catch (e) {
      console.error(e);
      return fallbackRoute;
    }
  };
};
