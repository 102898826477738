import { FC } from 'react';
import { CalendarPicker } from '@mui/lab';
import { Controller, FieldValues } from 'react-hook-form';
import { BaseControlledField } from '../interfaces';

export type ControlledCalendarProps<F extends FieldValues = any> = BaseControlledField<F> & any;

export const ControlledCalendar: FC<ControlledCalendarProps> = ({ control, name, required, rules = {}, ...props }) => {
  if (!!required) {
    rules.required = required;
  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, ...fieldProps } }) => (
        <CalendarPicker
          date={value}
          onChange={d => {
            onChange(d);
          }}
          {...fieldProps}
          {...props}
        />
      )}
    />
  );
};
