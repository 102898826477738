import { useMemo, FC } from 'react';
import {
  Grid,
  CircularProgress,
  Typography,
  Backdrop,
  GridProps,
} from '@mui/material';

export interface LoadingProps extends GridProps {
  onlyIcon?: boolean;
  backdrop?: boolean;
  text?: string;
}

export const Loading: FC<LoadingProps> = ({ text = 'Loading...', backdrop = false, onlyIcon = false, style, ...props }) => {
  const contents = useMemo(() => (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100%',
      }}
      style={style}
      {...props}
    >
      <Grid item>
        <CircularProgress size={24} color="secondary"/>
      </Grid>
      {!onlyIcon && (
        <Grid item>
          <Typography variant="overline" align="center" component="p">{text}</Typography>
        </Grid>
      )}
    </Grid>
  ), [text, onlyIcon]);

  if (backdrop) {
    return (
      <Backdrop
        open
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          color: '#fff',
        }}
      >
        {contents}
      </Backdrop>
    );
  }
  return contents;
};
