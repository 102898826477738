export function validateEmail(email: string) {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhoneNumber(phone?: string) {
  if (!phone) {
    return true;
  }
  const [prefix] = phone.split(' ');
  return !!prefix;
}

export function formatPhoneNumber(phone?: string) {
  if (!phone || !validatePhoneNumber(phone)) {
    return undefined;
  }
  const [, ...parts] = phone.split(' ');
  if (parts.length === 0) {
    return undefined;
  }
  return phone;
}
