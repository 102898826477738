import { FC, ReactNode } from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  RadioGroupProps,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { BaseControlledField } from '../interfaces';

export type ControlledRadioGroupProps = BaseControlledField & Omit<RadioGroupProps, 'value' | 'onChange'> & {
  options?: {
    value: string;
    label?: string;
  }[];
  children?: ReactNode[];
};

export const ControlledRadioGroup: FC<ControlledRadioGroupProps> = ({ control, name, required, rules = {}, children, options, ...props }) => {
  if (!!required) {
    rules.required = required;
  }

  const childrens = children ?? (options || []).map(opt => (
    <FormControlLabel key={opt.value} control={<Radio/>} label={opt.label} value={opt.value}/>
  ));

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange, name: pname } }) => (
        <RadioGroup
          {...props}
          name={pname}
          value={value}
          onChange={onChange}
        >
          {childrens}
        </RadioGroup>
      )}
    />
  );
};

export type ControlledFormControlRadioGroupProps = ControlledRadioGroupProps & {
  label: string | ReactNode;
}

export const ControlledFormControlRadioGroup: FC<
  ControlledFormControlRadioGroupProps
> = ({ label, ...props }) => (
  <FormControl component="fieldset">
    <FormLabel component="legend">{label}</FormLabel>
    <ControlledRadioGroup {...props}/>
  </FormControl>
);
