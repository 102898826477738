import { FC } from 'react';
import { DatePicker, DatePickerProps } from '@mui/lab';
import { TextField } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { BaseControlledField } from '../interfaces';

export type ControlledKeyboardDatePickerProps<F extends FieldValues = any> = BaseControlledField<F>
  & Omit<DatePickerProps, 'onChange' | 'value' | 'renderInput'> & {
  renderInput?: DatePickerProps['renderInput'];
};

export const ControlledKeyboardDatePicker: FC<
  ControlledKeyboardDatePickerProps
> = ({ control, name, rules = {}, required, ...props }) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { value, onChange } }) => (
      <DatePicker
        onChange={d => {
          if (!d || !isNaN(d.getTime())) {
            onChange(d);
          }
        }}
        views={['year', 'month', 'day']}
        inputFormat="dd/MM/yyyy"
        value={value}
        {...props}
        renderInput={params => props.renderInput ? props.renderInput(params) : <TextField {...params} helperText={null} />}
      />
    )}
  />
);
