import { FC } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

export type SubmitButtonProps = ButtonProps & {
  loading?: boolean;
  showLoading?: boolean;
};

export const SubmitButton: FC<SubmitButtonProps> = ({ children, loading, showLoading, startIcon, ...props }) => (
  <Button
    type="submit"
    color="primary"
    variant="contained"
    disabled={loading}
    startIcon={showLoading && loading ? <CircularProgress size="1rem"/> : startIcon}
    {...props}
  >
    {children}
  </Button>
);
