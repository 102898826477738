import { FC } from 'react';
import { Switch, SwitchProps as MuiSwitchProps } from '@mui/material';
import { ControlledFormControlLabel, ControlledFormControlLabelProps } from './controlled-form-control-label';

export type ControlledSwitchProps = Omit<ControlledFormControlLabelProps, 'formControl'> & {
  SwitchProps?: MuiSwitchProps;
  color?: MuiSwitchProps['color'];
};

export const ControlledSwitch: FC<ControlledSwitchProps> = ({ SwitchProps, color, ...props }) => (
  <ControlledFormControlLabel
    {...props}
    formControl={<Switch color={color} {...SwitchProps}/>}
  />
);
